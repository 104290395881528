<template>
  <div class="nurse-edit">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/overview' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/dashboard/nurse' }">护士管理</el-breadcrumb-item>
      <el-breadcrumb-item>修改护士</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-row>
        <el-form :model="editForm" :rules="editRules" ref="editRef" label-width="100px" class="edit-form">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="editForm.name"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="editForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="头像" prop="img">
            <upload :url="editForm.img" :filed="'img'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
          </el-form-item>
          <el-form-item label="归属医院">
            <el-select v-model="editForm.tid_hospital" placeholder="请选择归属医院" @change="hpChange">
              <el-option :label="item.title" :value="item.id" v-for="item in hospitalList" :key="item.id" :index="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="归属病区">
            <el-select v-model="editForm.tid_area" placeholder="请选择归属病区">
              <el-option :label="item.title" :value="item.id" v-for="item in areaList" :key="item.id" :index="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="审核状态">
            <el-radio-group v-model="editForm.status">
              <el-radio :label="1">待审核</el-radio>
              <el-radio :label="2">正常</el-radio>
              <el-radio :label="3">拉黑</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('editRef')">立即修改</el-button>
            <el-button @click="backUrl">返回</el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </div>
  </div>
</template>

<script>
import Upload from '../common/Upload'
export default {
  name: 'NurseEdit',
  components: {
    Upload
  },
  data () {
    return {
      hospitalList: [],
      areaList: [],
      editForm: {
        name: '',
        phone: '',
        img: '',
        tid_hospital: 0,
        tid_area: 0,
        status: 1
      },
      editRules: {
        name: [
          { required: true, message: '请输入护士姓名', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ],
        img: [
          { required: true, message: '请上传头像', trigger: 'blur' }
        ],
        area: [
          { required: true, message: '请输入护士负责区域', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        tid: [
          { required: true, message: '请选择归属医院', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getHospitalList()
    this.getSingleInfo()
  },
  methods: {
    // 获取医院列表
    async getHospitalList () {
      const { data: res } = await this.$http.get('/hospital-list')
      if (res.meta.status === 200) {
        this.hospitalList = res.data
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async getSingleInfo () {
      const { data: res } = await this.$http.get('/nurse/' + this.$route.params.id)
      if (res.meta.status === 200) {
        this.editForm.name = res.data.name
        this.editForm.phone = res.data.phone
        this.editForm.img = res.data.img
        this.editForm.tid_hospital = res.data.tid_hospital
        this.editForm.tid_area = res.data.tid_area
        this.editForm.status = res.data.status
        await this.getAreaList(res.data.tid_hospital)
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 获取医院下的病区
    async getAreaList (id) {
      const { data: res } = await this.$http.get('/limit-area/' + id)
      if (res.meta.status === 200) {
        this.areaList = res.data
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 当选择医院的值发生变化时重新获取病区列表
    hpChange (value) {
      this.areaList = []
      this.editForm.tid_area = 0
      this.getAreaList(value)
    },
    backUrl () {
      this.$router.push('/dashboard/nurse')
    },
    // 提交表单
    async submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.put('/nurse/' + this.$route.params.id, this.editForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            await this.$router.push('/dashboard/nurse')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.editForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.editForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.editForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.editForm[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>
.page-content{
  padding: 60px;
}
</style>
